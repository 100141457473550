import { render, staticRenderFns } from "./TemplateFiveList.vue?vue&type=template&id=beb236b8&scoped=true"
import script from "./TemplateFiveList.vue?vue&type=script&lang=js"
export * from "./TemplateFiveList.vue?vue&type=script&lang=js"
import style0 from "./TemplateFiveList.vue?vue&type=style&index=0&id=beb236b8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beb236b8",
  null
  
)

export default component.exports